<template>
  <v-app>
    <v-main>
      <v-container>
        <h1>Conférences</h1>
        <div v-if="!conferenceAffichage && !cacherInfoPageSecours">
          <v-card
            elevation="4"
            class="mt-4 mb-4"
            v-for="conference in conferences"
            :key="conference.nom"
          >
            <v-card-title>{{ conference.nom }}</v-card-title>
            <v-card-actions>
              <v-btn
                color="deep-purple"
                text
                @click="accederConference(conference)"
              >
                Accéder à la conférence
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div v-if="conferenceAffichage">
          <v-btn color="deep-purple" text @click="accederConference(null)">
            &lt; Retourner à la liste des conférences
          </v-btn>
          <cnx-video-player :url-presentation="conferenceAffichage.url" />
        </div>
        <div v-if="cacherInfoPageSecours">
          <v-alert text color="info">
            <div>
              Les conférences ne sont actuellement pas disponible. Veuillez
              communiquez avec le SOUTIEN TECHNIQUE à
              webdiffusion@connexence.com ou au 418 380-5815.
            </div>
          </v-alert>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="">
import Vue from "vue";
import CnxVideoPlayer from "./CnxVideoPlayer.vue";
import axios from "axios";

export default Vue.extend({
  components: {
    CnxVideoPlayer,
  },
  data() {
    return {
      conferenceAffichage: null,
      conferences: [
        {
          nom: "Jeudi : séances plénières AM",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
        {
          nom: "Jeudi PM / séance facultative #1: Prise en charge de l'HTA réfractaire et du fortuitome surrénalien",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
        {
          nom: "Jeudi PM / séance facultative #2: Nouveautés sur le sepsis",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq2.m3u8",
        },
        {
          nom: "Jeudi PM / séance facultative #3: Gestion des antiplaquettaires en cas d'athérosclérose cérébrale et périphérique",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
        {
          nom: "Jeudi PM / séance facultative #4: Gestion de la polypharmacie en cas d'IRA",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq2.m3u8",
        },
        {
          nom: "Jeudi : séances plénières PM",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
        {
          nom: "Vendredi : séances plénières toute la journée",
          url: "https://d1fj07sw7cr824.cloudfront.net/hls/fmoq1.m3u8",
        },
      ],
      cacherInfoPageSecours: null,
    };
  },
  created() {
    this.getParametres();
  },
  methods: {
    accederConference(conference) {
      this.conferenceAffichage = conference;
    },
    getParametres() {
      axios
        .get(
          "https://api-toucan.connexence.com/api/cnx-configurations/cacherInfoPageSecours"
        )
        .then((response) => {
          console.log(response);
          this.cacherInfoPageSecours = response.data;
        })
        .catch((erreur) => {
          console.log(erreur);
        });
    },
  },
});
</script>

<style lang="scss" scoped></style>
